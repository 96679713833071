<template>
  <div class="agreent" v-loading.fullscreen.lock="loading">
    <div style="padding: 0 20px 0 20px">
      <!-- <Lease-TopInfor :data="data"> -->
      <div class="top-infor-title row">
        <p class="title">Lease Agreement</p>
        <!-- <template slot="btn"> -->
        <el-button
          class="block-button"
          @click="downloadSignFile"
          :loading="downloading"
          >Download pdf</el-button
        >
      </div>

      <!-- </div> -->
      <!-- </template>
      </Lease-TopInfor> -->
      <div style="margin-top: 30px" class="showpdf">
        <iframe :src="src" width="100%" height="580px"></iframe>
      </div>
      <div class="all_btn">
        <div class="send_btn">
          <div class="have_btn">
            <p>{{ tenantSignedTimeDes }}</p>
            <a @click="sendSignFile('tenant')"
              >CONFIRM AND SEND FOR TENANT'S SIGNATURE</a
            >
          </div>
          <div class="have_btn">
            <p>{{ ownerSignedTimeDes }}</p>
            <a @click="sendSignFile('homeowner')"
              >RESEND LEASE TO OWNER FOR SIGNATURE</a
            >
          </div>
          <div>
            <el-upload
              class="upload-demo"
              action="upload-demo"
              :http-request="uploadSignedFile"
              :show-file-list="false"
            >
              <el-button class="download_btn block-button">
                <img src="../../assets/icon/icon-upload.svg" /> Upload Signed
                Lease
              </el-button>
            </el-upload>
          </div>
        </div>
      </div>
    </div>
    <div class="payment-bottom row">
      <el-button class="plight-blue-button" @click="back"
        ><p class="row">
          <i class="el-icon-back"></i><span>Previous</span>
        </p></el-button
      >
      <el-button class="primary-button" @click="next"
        ><p class="row"><span>Next</span><i class="el-icon-right"></i></p
      ></el-button>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import dayjs from "dayjs";
import {
  apiGetLease,
  apiSendSign,
  apiUploadSignedFile,
  apiGetSignFile,
} from "../../API/api";
import { downloadFile } from "../../API/utils";
import LeaseTopInfor from "./Component/LeaseTopInfor";
export default {
  data() {
    return {
      loading: false,
      downloading: false,
      src:
        "https://docs.google.com/viewer?embedded=true&url=" + axios.defaults.baseURL + "/get-sign-file?lease=" + this.$route.params.id,
      data: {},
    };
  },
  components: { LeaseTopInfor },
  computed: {
    tenantSignedAt() {
      // 租客签名时间
      if (
        this.data.sign_info &&
        this.data.sign_info.signature_request.signatures[0].signed_at
      ) {
        // 租客签名了
        return this.data.sign_info.signature_request.signatures[0].signed_at;
      } else if (  this.data.sign_file_info && this.data.sign_file_info.signed_at
      ) {
        // PM上传了双方签名后的签名文件
        return this.data.sign_file_info.signed_at;
      } else {
        return null;
      }
    },
    tenantSignedTimeDes() {
      // 租客签名时间描述
      if (this.tenantSignedAt) {
        return (
          "Tenant signed on " +
          dayjs(this.tenantSignedAt * 1000).format("MMMM DD,YYYY")
        );
      } else {
        return "Tenant has not yet signed";
      }
    },
    ownerSignedAt() {
      // 房主签名时间
      if (
        this.data.sign_info &&
        this.data.sign_info.signature_request.signatures[1].signed_at
      ) {
        // owner 自己签名了
        return this.data.sign_info.signature_request.signatures[1].signed_at;
      } else if (
        this.data.sign_file_info &&
        this.data.sign_file_info.signed_at
      ) {
        // PM代替owner上传签名文件
        return this.data.sign_file_info.signed_at;
      } else {
        return null;
      }
    },
    ownerSignedTimeDes() {
      // 房主签名时间描述
      if (this.ownerSignedAt) {
        return (
          "Owner signed on " +
          dayjs(this.tenantSignedAt * 1000).format("MMMM DD,YYYY")
        );
      } else {
        return "Owner has not yet signed";
      }
    },
  },
  methods: {
    back() {
      this.$router.push("/lease/" + this.$route.params.id + "/payment");
    },
    next() {
      this.$router.push(
        "/lease/" + this.$route.params.id + "/security-deposit"
      );
    },
    // 向租客发送租约
    sendSignFile(to) {
      this.loading = true;
      apiSendSign({ lease: this.$route.params.id, to: to })
        .then((res) => {
          this.$store.commit("setleaseAgeent", true);
          this.$message.success("Send successfully");
        })
        .catch((err) => {
          this.$message.error("Send fail, please try again later");
          this.$alert(err.response.data.detail, { confirmButtonText: "OK" });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    // 下载签名文件pdf
    downloadSignFile() {
      this.downloading = true;
      apiGetSignFile({ lease: this.$route.params.id })
        .then((res) => {
          downloadFile(
            res.data,
            res.headers["content-disposition"].split("=")[1],
            "application/pdf"
          );
          this.$message.success("Download sign file successful");
        })
        .catch((err) => {
          this.$message.error(
            "Download sign file fail, please try again later"
          );
        })
        .finally(() => {
          this.downloading = false;
        });
    },
    // 上传已签完名的签名文件
    uploadSignedFile(event) {
      // if (this.ownerSignedAt) {
      //   // owner 已经签名，不能再上传签名文件
      //   this.$message.error("The owner has signed, unable to upload the file.");
      //   return;
      // }
      if (event.file.type !== "application/pdf") {
        // 只能上传 pdf
        this.$message.error("Please upload pdf file");
        return;
      }
      this.loading = true;
      let data = new FormData();
      data.append("lease", this.$route.params.id);
      data.append("file", event.file);
      apiUploadSignedFile(data)
        .then((res) => {
          this.$message.success("Upload signed file successful");
          this.$router.go(0)
        })
        .catch((err) => {
          this.$message.error("Upload signed file fail, please try again later. "+err.response.data.detail
          );
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getLease() {
      apiGetLease(this.$route.params.id).then((res) => {
        this.$store.commit("setLease", JSON.parse(JSON.stringify(res)));
        this.data = res;
        if (this.data.sign_info || this.data.sign_file_info) {
          this.$store.commit("setLeaseAgeent", true);
        }
        this.$store.commit("setLeaseDetail", true);
        if (res.security_deposit.status === "Paid") {
          this.$store.commit("setLeaseSecurityDeposit", true);
        }
      });
    },
  },
  created() {
    if (this.$route.params.id) {
      if (this.$store.state.lease.lease_id === this.$route.params.id - 0) {
        this.data = JSON.parse(JSON.stringify(this.$store.state.lease));
        if (this.data.sign_info || this.data.sign_file_info) {
          this.$store.commit("setLeaseAgeent", true);
        }
      } else {
        this.getLease();
      }
    }
  },
};
</script>
<style lang="scss" scoped>
@import "../../css/button.scss";
.agreent {
  background-color: #fff;
  padding: 0px 0px 40px 0px;
  border-radius: 10px;
  .top-infor-title {
    margin-top: 20px;
    justify-content: space-between;
    .title {
      font-family: "Roboto-Bold", sans-serif;
      margin: 20px 0 0 0;
      font-size: 20px;
      text-align: left;
      color: #36425d;
    }
  }
  .payment-bottom {
    justify-content: space-between;
    position: relative;
    margin-top: 110px;
    padding: 20px 20px 0 20px;
    .el-button {
      padding: 0 20px;
      .row {
        justify-content: space-between;
        width: 100px;
        align-items: center;
        i {
          font-size: 18px;
        }
      }
    }
    &::after {
      content: "";
      border: 1px solid #e1e9ef;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
    }
  }
}
.showpdf {
  // height: 850px;
  /deep/ #title {
    display: none;
  }
  
  iframe {
    display: inherit;
    visibility: inherit;
  }
}

/* 按钮 */
.have_btn {
  text-align: left;
  font-family: "Roboto-Regular", sans-serif;
  color: #36425d;
  font-size: 14px;
  a {
    font-family: "Roboto-Bold", sans-serif;
    font-size: 14px;
    color: #678993;
    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }
  p {
    margin-bottom: 10px;
  }
}
.send_btn {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  display: -webkit-flex;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
}

.download_btn {
  font-size: 14px;
  font-family: "Roboto-Regular", sans-serif;
  border: 1px dashed #678993;
  height: 50px;
  i {
    margin-right: 10px;
  }
}
</style>
